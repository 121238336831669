<template>
  <div>
    <v-row v-if="createdStripeCustomerPageLink">
      <v-col col="12">
        <v-alert dense outlined type="success"
          >Successfully created customer access link:
          <b>{{ createdStripeCustomerPageLink }}</b>
          <v-icon @click="copyCreatedLink()">mdi-content-copy</v-icon></v-alert
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12">
        <v-data-table
          :headers="getHeaders()"
          :items="subscriptions"
          item-key="id"
          class="subscriptions-table"
        >
          <template v-slot:[`item.businessName`]="{ item }">
            <div class="no-wrap">
              <v-btn
                class="text-none text-left px-0"
                style="letter-spacing: normal; justify-content: left"
                text
                :to="'/master/business/' + item.businessId"
              >
                {{ getBusinessMark(item) }}
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="no-wrap">
              <v-chip small :color="getStatusColor(item)" text-color="white">
                {{ getStatus(item) }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.appId`]="{ item }">
            <div class="no-wrap">
              {{ (item.appId) }}
            </div>
          </template>
          <template v-slot:[`item.startTime`]="{ item }">
            <div class="no-wrap">
              {{ getFormattedDate(item.startTime) }}
            </div>
          </template>
          <template v-slot:[`item.expiryTime`]="{ item }">
            <div class="no-wrap">
              {{ getFormattedDate(item.expiryTime) }}
              <span v-if="getStatus(item) == 'Active'">
                &nbsp;({{ getRemainingDays(item) }} days)
              </span>
            </div>
          </template>
          <template v-slot:[`item.providerId`]="{ item }">
            <div class="no-wrap">
              {{ getProviderName(item.providerId) }}
            </div>
          </template>
          <template v-slot:[`item.plan`]="{ item }">
            <div class="no-wrap">
              {{ getPlanName(item.planId) }}
            </div>
          </template>
          <template v-slot:[`item.identifier`]="{ item }">
            <div class="no-wrap">
              {{ getSubscriptionIdentifier(item) }}
              <v-btn icon :href="getSubscriptionLink(item)" target="_blank">
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.json`]="{ item }">
            <v-dialog scrollable>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="default" small v-bind="attrs" v-on="on">
                  View
                </v-btn>
              </template>
              <v-card>
                <json-viewer :value="item"></json-viewer>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <!-- <v-list-item>
                      <v-list-item-title>
                        <v-btn v-if="isActiveSubscription(item)" text>
                          Make Inactive
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item> -->
                <v-list-item>
                  <v-list-item-title>
                    <v-btn
                      v-if="isStripeSubscription(item)"
                      text
                      @click="getStripeCustomerPortal(item)"
                    >
                      Get Customer Portal
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isRefundable(item)">
                  <v-list-item-title>
                    <cancel-subscription-dialog :subscription="item">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text v-bind="attrs" v-on="on">
                          <v-icon>mdi-cancel</v-icon>
                          Cancel and Refund
                        </v-btn>
                      </template>
                    </cancel-subscription-dialog>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isActive(item)">
                  <v-list-item-title>
                    <transfer-subscription-dialog
                      :subscription="item"
                      :businessName="getBusinessName(item)"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text v-bind="attrs" v-on="on">
                          <v-icon>mdi-credit-card</v-icon>
                          Transfer
                        </v-btn>
                      </template>
                    </transfer-subscription-dialog>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                      <v-btn text @click="copySubscriptionDetails(item)">
                        <v-icon>mdi-share</v-icon>
                        Copy
                      </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import JsonViewer from "vue-json-viewer";
import TransferSubscriptionDialog from "./TransferSubscriptionDialog.vue";
import CancelSubscriptionDialog from "./CancelSubscriptionDialog.vue";
import { truncate } from "lodash";
import { getStripeCustomerPage } from "../services/subscription/utilities";

const getHeaders = () => {
  return [
    { text: "Business Name", align: "start", value: "name" },
    { text: "Phone", value: "phone" },
    { text: "Plan", value: "plan" },
    { text: "Active Product", value: "activeProductId" },
    { text: "Expiry", value: "activePlanExpiry" },
    { text: "Actions", value: "actions" },
  ];
};

const isActive = (subscription) => {
  const now = Date.now();
  return now <= subscription.expiryTime && !subscription.replacedByAnotherSub;
};

export default {
  components: {
    JsonViewer,
    TransferSubscriptionDialog,
    CancelSubscriptionDialog,
  },
  data() {
    return {
      search: "",
      emailSubmitted: false,
      emailAddress: "",
      subscriptionHeaders: [
        { text: "Status", align: "start", value: "status" },
        { text: "Start Time", align: "start", value: "startTime" },
        { text: "Expiry Time", value: "expiryTime" },
        { text: "Plan", value: "plan" },
        { text: "Provider", value: "providerId" },
        { text: "Identifier", value: "identifier" },
        { text: "JSON", value: "json" },
        { text: "", value: "actions" },
      ],
      createdStripeCustomerPageLink: null,
    };
  },
  props: {
    subscriptions: {
      type: Array,
      required: true,
    },
    business: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      businessFetchWaiting: "business/isFetchWaiting",
      businessFetchError: "business/fetchError",
      businessList: "business/list",
      activeAccount: "realm/active",
      storedEmail: "business/email",
    }),
    headers() {
      return getHeaders();
    },
  },
  methods: {
    ...mapActions({
      fetchBusinesses: "business/fetchBusinesses",
    }),
    getHeaders() {
      const headers = [
        { text: "Status", align: "start", value: "status" },
        { text: "App ID", align: "start", value: "appId" },
        { text: "Start Time", align: "start", value: "startTime" },
        { text: "Expiry Time", value: "expiryTime" },
        { text: "Plan", value: "plan" },
        { text: "Provider", value: "providerId" },
        { text: "Identifier", value: "identifier" },
        { text: "JSON", value: "json" },
        { text: "", value: "actions" },
      ];
      if (!this.business) {
        headers.unshift({
          text: "Business",
          align: "start",
          value: "businessName",
        });
      }
      return headers;
    },
    getFormattedDate(date) {
      return date ? moment(date).format("ll") : "-";
    },
    getJson(item) {
      return JSON.stringify(item, null, 4);
    },
    getProviderName(id) {
      if (id == "FREE_TRIAL") return "Free Trail";
      if (id == "STRIPE_SUBSCRIPTION") return "Stripe";
      if (id == "PLAY_BILLING") return "Google Play";
      if (id == "Manual") return "Manual";
      if (id == "RAZORPAY_NON_RECURRING") return "Razorpay (One Time)";
      return id;
    },
    getSubscriptionLink(sub) {
      if (sub.providerId == "STRIPE_SUBSCRIPTION")
        return (
          "https://dashboard.stripe.com/subscriptions/" + sub.providerData.id
        );
      if (sub.providerId == "PLAY_BILLING")
        return (
          "https://play.google.com/console/u/0/developers/6773541816598877702/orders/" +
          sub.providerData.orderId
        );
      return "#";
    },
    getSubscriptionIdentifier(sub) {
      if (sub.providerId == "STRIPE_SUBSCRIPTION") return sub.providerData.id;
      if (sub.providerId == "PLAY_BILLING") return sub.providerData.orderId;
      return "#";
    },
    getPlanName(planId) {
      if (!planId) return "N/A";
      return planId == "p1m" ? "Monthly" : "Yearly";
    },
    getStatus(sub) {
      if (this.isActive(sub)) return "Active";
      else return "Expired";
    },
    getStatusColor(sub) {
      if (this.isActive(sub)) return "green";
      else return "gray";
    },
    getRemainingDays(sub) {
      return ((sub.expiryTime - Date.now()) / (1000 * 60 * 60 * 24)) | 0;
    },
    isStripeSubscription(sub) {
      if (sub.providerId == "STRIPE_SUBSCRIPTION") return true;
      return false;
    },
    isRefundable(sub) {
      // If provider is google play or stripe and is mutable / and the subscription is active
      if (
        this.isActive(sub) &&
        (sub.providerId == "STRIPE_SUBSCRIPTION" ||
          sub.providerId == "PLAY_BILLING")
      ) {
        return true;
      }
      return false;
    },
    isActive(sub) {
      return isActive(sub);
    },
    getBusinessName(sub) {
      if (!sub.businessId) return "** Unlinked **";
      if (this.business && sub.businessId == this.business.id)
        return this.business.name;
      const business = this.getBusinessFromList(sub.businessId);
      if (business) return business.name;
      return "** Deleted or No Access **";
    },
    getBusinessMark(sub) {
      if (!sub.businessId) return "** Unlinked **";
      if (this.business && sub.businessId == this.business.id)
        return (
          truncate(this.business.name, { length: 10, omission: ".." }) +
          "|" +
          this.businessId
        );
      const business = this.getBusinessFromList(sub.businessId);
      if (business)
        return (
          truncate(business.name, { length: 12, omission: ".." }) +
          " | " +
          sub.businessId
        );
      return "** NA ** | " + sub.businessId;
    },
    getBusinessFromList(businessId) {
      if (!this.businessList || !this.businessList.length) return null;
      for (let business of this.businessList) {
        if (business.id == businessId) {
          return business;
        }
      }
      return null;
    },
    async getStripeCustomerPortal(sub) {
      const response = await getStripeCustomerPage(
        this.activeAccount.id,
        sub.id
      );

      this.createdStripeCustomerPageLink = response.url;
    },
    copyCreatedLink() {
      navigator.clipboard.writeText(this.createdStripeCustomerPageLink);
      this.$notifier.showMessage({
        content: "Copied",
      });
    },
    copySubscriptionDetails(sub) {
      navigator.clipboard.writeText(
        JSON.stringify(
          {
            businessId: sub.businessId,
            businessName: this.getBusinessName(sub),
            email: this.emailAddress,
            providerData: sub.providerData,
            plan: this.getPlanName(sub.planId),
            provider: this.getProviderName(sub.providerId),
            identifier: this.getSubscriptionIdentifier(sub),
            link: this.getSubscriptionLink(sub),
          })
      );
      this.$notifier.showMessage({
        content: "Copied subscription details to clipboard",
      });
    },
  },
  mounted() {
    if (this.storedEmail) {
      this.emailAddress = this.storedEmail;
      this.emailSubmitted = true;
    }
  },
};
</script>
