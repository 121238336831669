<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" dark :attrs="attrs" :on="on">
        <v-btn color="primary" dark v-bind="attrs" v-on="on"> Dummy </v-btn>
      </slot>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Confirm action</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col col="12">
              <h3>
                Are you sure you want to cancel and refund this subscription?

                {{ subscription.providerId }} - {{ subscription.plan }}
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-combobox
                v-model="cancellationReason"
                :items="cancellationReasons"
                required
                label="Please enter the cancellation reason (You can also enter your own reason)"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row v-if="cancellationDetails">
            <v-col col="12">
              <h3>
                Refund Possible: {{ cancellationDetails.canRefund }}
                <br />Refund Amount: {{ cancellationDetails.refundAmount }}
              </h3>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="cancelSubscriptionAndRefund"
          v-if="
            cancellationReason &&
            cancellationDetails &&
            cancellationDetails.canRefund
          "
        >
          Cancel Subscription and Refund
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import config from "../../config/config";
import businessService from "../services/subscription/business";
import {
  cancelSubscription,
  enquireCancelSubscription,
} from "../services/subscription/utilities";

export default {
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cancellationReason: null,
      cancellationDetails: null,
      dialog: false,
      numberOfDays: null,
      selectedPeriod: "1 year",
      notes: null,
      confirmBusinessName: null,
      cancellationReasons: [
        "Purchased by mistake",
        "Closing down the shop",
        "Features missing",
        "Waited too long for missing features",
        "Too expensive",
        "Not useful",
      ],
    };
  },
  computed: {
    ...mapGetters({
      activeAccount: "realm/active",
      businessList: "business/list",
    }),
  },
  watch: {
    dialog: {
      handler(new_, old) {
        console.log({ old, new_ });
        if (new_) {
          if (this.subscription != null) this.getCancellationDetails();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      fetchBusinesses: "business/fetchBusinesses",
    }),
    async verifyBusinessId() {
      if (
        !this.targetBusinessId ||
        this.targetBusinessId == this.subscription.businessId
      ) {
        this.$notifier.showMessage({
          content: "Invalid input. Enter a valid business Id",
        });
        return;
      }
      let businessName = await businessService.getBusinessNameFromId(
        this.activeAccount.id,
        this.targetBusinessId
      );
      if (businessName) {
        this.confirmBusinessName = businessName;
      } else {
        this.$notifier.showMessage({
          content:
            "No business found with the given ID. Please check the input",
        });
      }
    },
    async getCancellationDetails() {
      try {
        const cancellationDetails = await enquireCancelSubscription({
          realmId: this.activeAccount.id,
          subscriptionId: this.subscription.id,
          cancellationReason: "",
        });
        if (cancellationDetails != null)
          this.cancellationDetails = cancellationDetails;
      } catch (error) {
        console.error(error);
        if (error.response?.data?.errorMessage) {
          this.$notifier.showMessage({
            content:
              "Cancel subscription failed. Error: " +
                error.response?.data?.errorMessage || "unknown",
          });
        } else {
          this.$notifier.showMessage({
            content:
              "Cancel subscription failed. Error: " + error.message ||
              "unknown",
          });
        }
      }
    },
    async cancelSubscriptionAndRefund() {
      this.dialog = false;
      try {
        this.$notifier.showMessage({
          content: "Cancelling..! Please wait...",
        });
        await cancelSubscription({
          realmId: this.activeAccount.id,
          sourceBusinessId: this.subscription.businessId,
          subscriptionId: this.subscription.id,
          cancellationReason: this.cancellationReason,
        });
        this.$notifier.showMessage({
          content: "Subscription has been cancelled.",
        });
        this.dialog = false;
      } catch (error) {
        console.error(error);
        this.$notifier.showMessage({
          content:
            "Cancel subscription failed. Error: " + error.message || "unknown",
        });
      }
    },
  },
  mounted() {
    if (!this.businessList) this.fetchBusinesses();
  },
};
</script>
