<template>
  <v-container>
    <div v-if="!customerEmail && !customerWaiting">
      <v-row class="email-form">
        <v-col col="12">
          <v-text-field
            label="Enter customer email address "
            color="primary"
            type="text"
            v-model="customerEmailInput"
            prepend-icon="mdi-email"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col col="12">
          <v-btn
            color="primary"
            @click="submitEmailAndFetchBusinesses"
            type="button"
            class="btn btn-primary"
            style="margin: 0 auto; display: block"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="app-loader" v-else-if="customerWaiting">
      <v-progress-circular
        class="mx-auto"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else-if="customerError">
      <v-alert style="width: 100%" type="error">
        Could not fetch customer details. Error: {{ businessFetchError }}
      </v-alert>
    </div>
    <div class="actions-controls" v-else>
      <div class="business-section">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card>
              <v-card-title>
                All Businesses
                <v-spacer></v-spacer>
              </v-card-title>
              <div class="app-loader" v-if="businessFetchWaiting">
                <v-progress-circular
                  class="mx-auto"
                  :size="70"
                  :width="7"
                  indeterminate
                ></v-progress-circular>
              </div>
              <v-row v-else-if="businessFetchError">
                <v-alert style="width: 100%" type="error">
                  Could not fetch the business list. {{ businessFetchError }}
                </v-alert>
              </v-row>
              <div class="business-section" v-else>
                <user-businesses :businesses="businessList"></user-businesses>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-spacer></v-spacer>
      <div class="subscriptions-section">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card>
              <v-card-title>
                All Subscriptions
                <v-spacer></v-spacer>
              </v-card-title>
              <div class="app-loader" v-if="subscriptionFetchWaiting">
                <v-progress-circular
                  class="mx-auto"
                  :size="70"
                  :width="7"
                  indeterminate
                ></v-progress-circular>
              </div>
              <v-row v-else-if="subscriptionFetchError">
                <v-alert style="width: 100%" type="error">
                  Could not fetch the business list.
                  {{ subscriptionFetchError }}
                </v-alert>
              </v-row>
              <div class="business-section" v-else>
                <subscriptions-list
                  :subscriptions="subscriptionList"
                ></subscriptions-list>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import userBusinesses from "../../components/user/user-businesses.vue";
import SubscriptionsList from "../../components/subscriptions-list.vue";

function copyToClipboard(text) {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

export default {
  components: {
    userBusinesses,
    SubscriptionsList,
  },
  data() {
    return {
      usersub: true,
      businesssub: false,
      pricing: false,
      customerEmailInput: "",
    };
  },
  computed: {
    ...mapGetters({
      activeAccount: "realm/active",
      businessFetchWaiting: "business/isFetchWaiting",
      businessFetchError: "business/fetchError",
      businessList: "business/list",
      subscriptionFetchWaiting: "subscription/isFetchWaiting",
      subscriptionFetchError: "subscription/fetchError",
      subscriptionList: "subscription/list",
      customerEmail: "customerEmail",
      customerId: "customerId",
      customerWaiting: "customerWaiting",
      customerError: "customerError",
    }),
    businessesLinked() {
      return this.businessList.length;
    },
    totalSalesMade() {
      const businessWithSales = this.businessList.filter(
        (x) => x.activePlanId != null
      );
      return businessWithSales.length;
    },
  },
  methods: {
    ...mapActions({
      fetchCustomerDetails: "fetchCustomerDetails",
      fetchBusinesses: "business/fetchBusinesses",
      fetchSubscriptions: "subscription/fetchSubscriptions",
    }),
    ...mapMutations({
      setCustomer: "setCustomer",
    }),
    getFormattedDate(date) {
      return date ? moment(date).format("ll") : "-";
    },
    sharePartnerLink() {
      // Get the business open link here
      const partnerLink = this.activeAccount.shareLink;
      if (partnerLink) {
        if (navigator.share) {
          navigator
            .share({
              title: "Zobaze POS",
              text: "Go digital with Zobaze POS.",
              url: partnerLink,
            })
            .then(() => console.log("Successful share"))
            .catch((error) => console.log("Error sharing", error));
        } else {
          copyToClipboard(partnerLink);
          this.$notifier.showMessage({
            content: "Link copied to clipboard",
          });
        }
      } else {
        this.$notifier.showMessage({
          content:
            "Share code has not been created for this account. Please contact us via chat or email to create it",
        });
      }
    },
    async submitEmailAndFetchBusinesses() {
      this.emailSubmitted = true;
      await this.fetchCustomerDetails(this.customerEmailInput);
      // this.setCustomer({ email: this.customerEmailInput });
      await Promise.all([
        this.fetchBusinesses({
          realmId: this.activeAccount.id,
          email: this.customerEmailInput,
        }),
        this.fetchSubscriptions({
          realmId: this.activeAccount.id,
          userId: this.customerId,
        }),
      ]);
    },
  },
  mounted() {
    if (this.customerEmail && !this.businessList.length) {
      this.fetchBusinesses({
        realmId: this.activeAccount.id,
        email: this.customerEmail,
      });
    }

    if (this.customerEmail && !this.subscriptionList.length) {
      this.fetchSubscriptions({
        realmId: this.activeAccount.id,
        userId: this.customerId,
      });
    }
  },
};
</script>

<style>
.home {
  min-height: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.centered-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
</style>
