<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" dark :attrs="attrs" :on="on">
        <v-btn color="primary" dark v-bind="attrs" v-on="on"> Dummy </v-btn>
      </slot>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Confirm action</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col col="12">
              <h3>
                Please enter the business ID of the business for which the
                subscription should be transferred
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-select
                v-model="targetBusinessId"
                :items="businessList"
                item-text="name"
                item-value="id"
                label="Business"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-btn
              color="primary"
              :enabled="targetBusinessId"
              @click="verifyBusinessId"
              >verify</v-btn
            >
          </v-row>
          <v-row v-if="confirmBusinessName" class="mt-6">
            <h3>
              Confirm transfer from :<br />
              <b>{{ businessName || "Deleted" }}</b>
              <v-icon>mdi-arrow-right</v-icon> <b>{{ confirmBusinessName }}</b
              >?
              <br />
              Please proceed carefully.
            </h3>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="transferSubscription"
          v-if="confirmBusinessName"
        >
          Confirm Transfer!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import config from "../../config/config";
import businessService from "../services/subscription/business";
import { transferSubscription } from "../services/subscription/utilities";

export default {
  props: {
    businessName: {
      type: String,
      required: true,
    },
    subscription: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      targetBusinessId: null,
      dialog: false,
      numberOfDays: null,
      selectedPeriod: "1 year",
      notes: null,
      confirmBusinessName: null,
    };
  },
  computed: {
    ...mapGetters({
      activeAccount: "realm/active",
      businessList: "business/list",
    }),
  },
  methods: {
    ...mapActions({
      fetchBusinesses: "business/fetchBusinesses",
    }),
    async verifyBusinessId() {
      if (
        !this.targetBusinessId ||
        this.targetBusinessId == this.subscription.businessId
      ) {
        this.$notifier.showMessage({
          content: "Invalid input. Enter a valid business Id",
        });
        return;
      }
      let businessName = await businessService.getBusinessNameFromId(
        this.activeAccount.id,
        this.targetBusinessId
      );
      if (businessName) {
        this.confirmBusinessName = businessName;
      } else {
        this.$notifier.showMessage({
          content:
            "No business found with the given ID. Please check the input",
        });
      }
    },
    async transferSubscription() {
      this.dialog = false;
      try {
        this.$notifier.showMessage({
          content: "Transferring..! Please wait...",
        });
        await transferSubscription({
          realmId: this.activeAccount.id,
          sourceBusinessId: this.subscription.businessId,
          subscriptionId: this.subscription.id,
          targetBusinessId: this.targetBusinessId,
        });
        this.$notifier.showMessage({
          content: "Transferred subscription successfully",
        });
        this.dialog = false;
      } catch (error) {
        console.error(error);
        this.$notifier.showMessage({
          content:
            "Transfer subscription failed. Error: " + error.message ||
            "unknown",
        });
      }
    },
  },
  mounted() {
    if (!this.businessList || !this.businessList.length) this.fetchBusinesses();
  },
};
</script>
