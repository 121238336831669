<template>
  <div>
    <div class="mx-4">
      <v-text-field
        class="mx-4 my-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>

      <v-data-table
        :headers="headers"
        :items="getTableItems()"
        :search="search"
        item-key="id"
        :footer-props="{
          'items-per-page-options': [50, 100, 200],
        }"
        :items-per-page="50"
        class="business-table"
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-btn
            class="text-none text-left px-0"
            style="letter-spacing: normal; justify-content: left"
            text
            :to="'/master/business/' + item.id"
          >
            {{ item.name }}
          </v-btn>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <div @click="openEmail(item.email)">
            {{ item.email }}
          </div>
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          <div @click="openPhone(item.phone)">
            {{ item.phone }}
          </div>
        </template>
        <template v-slot:[`item.plan`]="{ item }">
          <div>
            <div class="no-wrap">
              {{ getPlanName(item) }}
            </div>

            <div class="text-small" v-if="item.activePlanExpiry">
              <span class="no-wrap">
                exp:
                {{ getFormattedDate(item.activePlanExpiry) }}
              </span>
              <span v-if="item.isSubscriptionLinked == false">
                Already Subscribed
              </span>
            </div>
          </div>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          <div class="no-wrap">
            {{ item.created }}
          </div>
        </template>
        <template v-slot:[`item.linkedAt`]="{ item }">
          <div class="no-wrap">
            {{ item.linkedAt }}
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div>
            <div class="no-wrap">
              {{ getPlanName(item) }}
            </div>

            <div class="text-small" v-if="item.activePlanExpiry">
              <span class="no-wrap">
                exp:
                {{ getFormattedDate(item.activePlanExpiry) }}
              </span>
              <span v-if="item.isSubscriptionLinked == false">
                Already Subscribed
              </span>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

const getFormattedDate = (date) => {
  return date ? moment(date).format("ll") : "-";
};

const getHeaders = () => {
  return [
    { text: "Business Name", align: "start", value: "name" },
    { text: "ID", align: "start", value: "id" },
    { text: "Phone", value: "phone" },
    { text: "Plan", value: "plan" },
    { text: "Active Product", value: "activeProductId" },
    { text: "Expiry", value: "activePlanExpiry" },
    { text: "Actions", value: "actions" },
  ];
};

const getTableItem = (business) => {
  return {
    id: business.id,
    name: business.name,
    email: business.email,
    phone: business.phone,
    plan: business.activePlanId,
    activePlanExpiry: business.activePlanExpiry
      ? getFormattedDate(business.activePlanExpiry)
      : "",
    activeProductId: business.activeProductId,
  };
};

export default {
  data() {
    return {
      search: "",
      emailSubmitted: false,
      emailAddress: "",
    };
  },
  computed: {
    ...mapGetters({
      businessFetchWaiting: "business/isFetchWaiting",
      businessFetchError: "business/fetchError",
      businessList: "business/list",
      activeAccount: "realm/active",
      storedEmail: "business/email",
    }),
    headers() {
      return getHeaders();
    },
  },
  methods: {
    ...mapActions({
      fetchBusinesses: "business/fetchBusinesses",
    }),
    getFormattedDate(date) {
      return date ? moment(date).format("ll") : "-";
    },
    openEmail(email) {
      window.open("mailto:" + email, "_blank");
    },
    openPhone(phone) {
      if (!phone) return;
      window.open("tel:" + phone);
    },
    getPlanName(business) {
      if (!business.activeProductId) return "No Plan";
      return (
        business.activeProductId +
        " - " +
        (business.activePlanId == "p1m" ? "monthly" : "yearly")
      );
    },
    getTableItems() {
      let items = [];
      for (let business of this.businessList) {
        items.push(getTableItem(business));
      }
      return items;
    },
    submitEmailAndFetchBusinesses() {
      this.emailSubmitted = true;
      this.fetchBusinesses({
        realmId: this.activeAccount.id,
        email: this.emailAddress,
      });
    },
  },
  mounted() {
    if (this.storedEmail) {
      this.emailAddress = this.storedEmail;
      this.emailSubmitted = true;
    }
  },
};
</script>
